import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FileSelectComponent } from './file-select/file-select.component';

import { FileConfigComponent } from './file-config.component';
import { Test1Component } from './test1/test1.component';

const routes: Routes = [
  { path: '', component: FileConfigComponent },
  { path: 'file-select', component: FileSelectComponent },
  {path: 'test' , component: Test1Component}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FileConfigRoutingModule { }
