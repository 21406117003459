import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NextConfig} from '../../../../app-config';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import jwt_decode from 'jwt-decode'
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavBarComponent implements OnInit {
  public nextConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;
  dropdown:boolean=false;
  tokendecrypt:any = "";

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(private router: Router) {
    this.nextConfig = NextConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
  }

  // ngOnInit() { }
  ngOnInit() {
    let token = localStorage.getItem('accessToken')
    this.tokendecrypt = jwt_decode(token)
   }
  logOut(){
    localStorage.clear();
    this.router.navigate(['/auth/signin']);
  }
  profileDetail(){
    this.router.navigate(["/user/details"]);

  }
  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
