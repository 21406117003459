import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileConfigRoutingModule } from './file-config-routing.module';
import { FileConfigComponent } from './file-config.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialDesignModule } from '../material-design/material-design.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../theme/shared/shared.module';
import { FileSelectComponent } from './file-select/file-select.component';
import { Test1Component } from './test1/test1.component';
import { MatButtonModule, MatIconModule, MatTreeModule } from '@angular/material';

import { RuleConfigModule } from '../rule-config/rule-config.module';



@NgModule({
  declarations: [FileConfigComponent, FileSelectComponent, Test1Component],
  imports: [
    CommonModule,
    FileConfigRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    NgbDropdownModule,
    SharedModule,
    MatTreeModule,
    MatIconModule, 
    MatButtonModule,
    RuleConfigModule
    
  ]
})
export class FileConfigModule { }
