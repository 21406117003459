import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';


@Injectable({
  providedIn: 'root'
})
export class FileConfigService {
  baseUrl: any;
  constructor(private http: HttpClient, private router: Router, private envService: EnvService) {
    this.baseUrl = envService.apiUrl
  }
  createValidation(validation) {
    return this.http.post(`${this.baseUrl}/fileconfig/createvalidations`, validation);
  }
  getValidation(validation) {
    return this.http.post(`${this.baseUrl}/fileconfig/getvalidations`, validation);
  }
  createMapfields(validation) {
    return this.http.post(`${this.baseUrl}/fileconfig/createmapfields`, validation);
  }
}