import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnersetupService } from '../shared/partnersetup.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-file-config',
  templateUrl: './file-config.component.html',
  styleUrls: ['./file-config.component.css']
})
export class FileConfigComponent implements OnInit {
  partnerData: any = [];
  selectedData: any = [];

  constructor(private partnerSetupService: PartnersetupService, private router: Router) { }

  ngOnInit() {
    this.partnerSetupService.getPartnerDetail().subscribe((data => {
      this.partnerData['country1'] = "check"

      this.partnerData = data;
    }))
  }
  onSelect(event) {
    this.selectedData = this.partnerData.filter(item => item.name == event.value)

  }
  OnSubmit(type) {
    if (type == 'Inventory') {
      if (this.selectedData.length != 0) {
        this.router.navigate(['/file-config/file-select']);
        this.partnerSetupService.name=this.selectedData[0].name
        this.partnerSetupService.id=this.selectedData[0].partnerid
   
      }
      else{
        swal.fire("Select Partner", "Partner Cannot be empty ", "error")
      }
    }
    else {
      if (this.selectedData.length != 0) {
        this.router.navigate(['/file-select/data-upload']);
        this.partnerSetupService.name=this.selectedData[0].name
        this.partnerSetupService.name=this.selectedData[0].partnerid
 
      }
      else{
        swal.fire("Select Partner", "Partner Cannot be empty ", "error")
      }
    }
  }

}
