import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl: any;
  role: any;
  route_url = "";
  constructor(private http: HttpClient, private router: Router, private envService: EnvService) {
    this.baseUrl = envService.apiUrl
  }
  getLoginDetail(user) {
    return this.http.post(`${this.baseUrl}/user/login`, user);

  }
  setPassword(resetPasswordPayload) {
    return this.http.post(`${this.baseUrl}/password/resetPassword`, resetPasswordPayload);

  }



  arrayOfObjectMapping(key, documentRecords) {
    let currentMappedItemStatus: any = {};
    if (documentRecords) {
      for (let i = 0; i < documentRecords.length; i++) {
        if (documentRecords[i]) {
          if (currentMappedItemStatus.hasOwnProperty(documentRecords[i][key])) {
            currentMappedItemStatus[documentRecords[i][key]].push(documentRecords[i]);
          } else {
            currentMappedItemStatus[documentRecords[i][key]] = [documentRecords[i]];
          }
        }
      }
    }
    return currentMappedItemStatus;
  }
  async isLoggedIn(url): Promise<boolean> {
    try {
      this.route_url = url;
      if (!this.getCookie("accessToken")) {
        this.router.navigate(["/auth/signin"]);
        return false;
      }

      return true;
    } catch (err) {
      console.log("err is is logged in check", err);
    }
  }

  getCookie(cname) {
    return localStorage.getItem(cname);
  }

  getDashboarDetail() {
    return this.http.get(`${this.baseUrl}/user/getDashboardData`);
  }

}
