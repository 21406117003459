import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import jwt_decode from 'jwt-decode'

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  dropdown:boolean=false;
  tokendecrypt:any = "";

  constructor(private router: Router) { }

  ngOnInit() {
    let token = localStorage.getItem('accessToken')
    this.tokendecrypt = jwt_decode(token)
   }
  logOut(){
    localStorage.clear();
    this.router.navigate(['/auth/signin']);
  }
  profileDetail(){
    this.router.navigate(["/user/details"]);

  }
}
