import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { FileConfigService } from '../../shared/fileconfig.service'
import swal from 'sweetalert2'
import { PartnersetupService } from 'src/app/shared/partnersetup.service';



@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss']
})
export class FileSelectComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  excelData: any;
  headers: any;
  cdg: any;
  myControl = new FormControl();
  jsonModelFinalData: any;
  mapFieldsData:any=[];
  isLinear: any;
  options: string[] = ['UNIQUE_ID', 'ACTION_FLAG', 'SITE_USE_ID', 'CUSTOMER_NAME',
    'CUSTOMER_TYPE', 'WEEK_ENDING_DATE', 'TRANSACTION_DATE', 'ITEM_NUMBER', 'LOCATION_ID', 'LOCATION_NAME',
    'OH_QTY', 'OH_Dollars', 'OO_QTY', 'Other_QTY', 'RECORD_CREATION_DATETIME',
    'RECORD_LAST_MODIFICATION_DATETIME', 'PARTNER_TYPE'];
  POS: string[] = ['UNIQUE_ID', 'ACTION_FLAG', 'SITE_USE_ID', 'CUSTOMER_NAME', 'CUSTOMER_TYPE', 'WEEK_ENDING_DATE',
    'TRANSACTION_DATE', 'ITEM_NUMBER', 'LOCATION_ID', 'LOCATION_NAME', 'ST_QTY', 'ST_Dollars',
    'RECORD_CREATION_DATETIME', 'RECORD_LAST_MODIFICATION_DATETIME', 'TIER1_CHANNEL', 'TIER 1_SALES_REP',
    'STANDARD_COST', 'ASP1', 'ASP2', 'PARTNER TYPE', 'Sold To Customer Name', 'Sold To Address',
    'Sold To Address Line2', 'Sold to City', 'Sold To State', 'Sold To Country', 'Sold to ZIP',
    'Bill To Customer Name', 'Bill To Address', 'Bill To Address Line2', 'Bill to City', 'Bill To State',
    'Bill To Country', 'Bill to ZIP', 'Ship To Customer Name', 'Ship to Address', 'Ship to Address Line2',
    'Ship to City', 'Ship to State', 'Ship to Country', 'Ship to ZIP', 'FLAG', 'Rebate_Reference#', 'SERIAL_NUMBER',
    'Placeholder_1', 'Placeholder_2', 'Placeholder_3'];
  filteredOptions: Observable<string[]>;

  constructor(private _formBuilder: FormBuilder, private fileConfigService: FileConfigService, private partnerSetupService: PartnersetupService) { }

  ngOnInit() {

   
    this.getData()

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

  }
  readExcelFile(event) {
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.excelData = (XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.headers = this.excelData[0];
      this.jsonModelFinalData = this.getJsonModel();
    };
    reader.readAsBinaryString(target.files[0]);

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }


  onSelectionChange(_data) {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
  nodeData = [{}]
  getJsonModel() {
    let sampleData = {}
    let previousData = {}
  
    for (let i = 0; i < this.headers.length; i++) {
      let name1 = this.headers[i]
        sampleData = {
         'name' : name1,
         'selectedValue':''
  
         }
       this.nodeData[i] = sampleData
    }
    return this.nodeData;
  }
  onSelect(event, header) {
 
    for(let i =0;i<this.jsonModelFinalData.length;i++){
      if(this.jsonModelFinalData[i].name == header){
        this.jsonModelFinalData[i].selectedValue = event.option.value;
      }
    }

  }
  onSubmit() {
    let userId = localStorage.getItem('userData')
    let name =this.partnerSetupService.name
    let id =this.partnerSetupService.id
    let validation = {};
    validation = {
      "mapfields": {
        "userid": userId,
        "mapfields": this.jsonModelFinalData,
        "partnername":name,
        "partnerid":id
      }
    }
    this.fileConfigService.createMapfields(validation).subscribe((_data) => {


      swal.fire("Saved MapFields", "Mapfields Has Been Saved Successfully", "success");
      this.getData();
    
    },
      _err => swal.fire("MapFields not created", "", "error")
    )

  }
  reset() {
    let userId = localStorage.getItem('userData')
    let name =this.partnerSetupService.name
    let id =this.partnerSetupService.id
    let validation = {};
    validation = {
      "mapfields": {
        "userid": userId,
        "mapfields": null,
        "partnername":name,
        "partnerid":id
      }
    }
    this.fileConfigService.createMapfields(validation).subscribe((_data) => {


      swal.fire("Reseted MapFields", "Mapfields Has Been Reseted Successfully", "success");
  
      this.getData();
    },
      _err => swal.fire("MapFields not Reseted", "", "error")
    )

  }
  bindedValue:any=[];
  enable:boolean;
  getData() {
    let userId = localStorage.getItem('userData')
    let id =this.partnerSetupService.id
    let validation = {};
    validation = {
      "getvalidationsfileconfig": {
        "userid": userId,
        "partnerid":id

      }
    }
    this.fileConfigService.getValidation(validation).subscribe((data) => {


      this.mapFieldsData=data
      if (this.mapFieldsData.length != 0) {
        if (data[0]['mapfields'] != null) {
          let res = data[0]['mapfields']
          this.headers =res
          this.jsonModelFinalData =res;
 
          this.enable =false;
          
     
        }
        else {
          this.headers = [];
          this.bindedValue=[];
          this.enable=true;
        }
      }
      else {
        this.headers = [];
        this.bindedValue=[];
        this.enable=true;
      }
 
    },
      err => console.log(err)
    )

  }
}
