import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { NotfoundComponent } from "./notfound/notfound.component";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";

const routes: Routes = [
  {
    path: "",
    component: AuthComponent,
    children: [
     
      {
        path: "",
        redirectTo: "auth/signin",
        pathMatch: "full",
      },
      {
        path: "auth",
        loadChildren: () =>
          import("./components/pages/authentication/authentication.module").then(
            (module) => module.AuthenticationModule
          ),
      },
      {
        path: "maintenance",
        loadChildren: () =>
          import("./components/pages/maintenance/maintenance.module").then(
            (module) => module.MaintenanceModule
          ),
      },
    ],
  },
  {
    path: "",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '404', component:NotfoundComponent},
      // {
      //   path: '',
      //   redirectTo: 'dashboard/analytics',
      //   pathMatch: 'full'
      // },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./components/dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: "layout",
        loadChildren: () =>
          import("./components/pages/layout/layout.module").then(
            (module) => module.LayoutModule
          ),
      },
      {
        path: "basic",
        loadChildren: () =>
          import("./components/ui-elements/ui-basic/ui-basic.module").then(
            (module) => module.UiBasicModule
          ),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./components/pages/form-elements/form-elements.module").then(
            (module) => module.FormElementsModule
          ),
      },

      {
        path: "tbl-bootstrap",
        loadChildren: () =>
          import("./components/pages/tables/tbl-bootstrap/tbl-bootstrap.module").then(
            (module) => module.TblBootstrapModule
          ),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./components/pages/core-chart/core-chart.module").then(
            (module) => module.CoreChartModule
          ),
      },
      {
        path: "sample-page",
        loadChildren: () =>
          import("./components/pages/sample-page/sample-page.module").then(
            (module) => module.SamplePageModule
          ),
      },
   
  

      {
        path: "results",

        loadChildren: () =>
          import("./results/results.module").then((m) => m.ResultsModule),
      },
      {
        path: "exceptions",

        loadChildren: () =>
          import("./exceptions/exceptions.module").then(
            (m) => m.ExceptionsModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./admin-category/user/user.module").then((m) => m.UserModule),
      },
      {
        path: "personalUser",
        loadChildren: () =>
          import("./admin-category/user/personal-user/personal-user.module").then((m) => m.PersonalUserModule),
      },
      {
        path: "role",

        loadChildren: () =>
          import("./admin-category/role/role.module").then((m) => m.RoleModule),
      },
      {
        path: "file-config",

        loadChildren: () =>
          import("./file-config/file-config.module").then(
            (m) => m.FileConfigModule
          ),
      },
      {
        path: "rule-config",

        loadChildren: () =>
          import("./rule-config/rule-config.module").then(
            (m) => m.RuleConfigModule
          ),
      },
    ],
  },

 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
