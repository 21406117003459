import { NgModule } from "@angular/core";
import * as Material from "@angular/material";
const MaterialComponents = [
  Material.MatAutocompleteModule,
  Material.MatButtonToggleModule,
  Material.MatChipsModule,
  Material.MatDatepickerModule,
  Material.MatDialogModule,
  Material.MatExpansionModule,
  Material.MatMenuModule,
  Material.MatNativeDateModule,
  Material.MatPaginatorModule,
  Material.MatProgressBarModule,
  Material.MatProgressSpinnerModule,
  Material.MatRadioModule,
  Material.MatRippleModule,
  Material.MatSelectModule,
  Material.MatSliderModule,
  Material.MatSlideToggleModule,
  Material.MatSnackBarModule,
  Material.MatSortModule,
  Material.MatTableModule,
  Material.MatTabsModule,
  Material.MatToolbarModule,
  Material.MatTooltipModule,
  Material.MatStepperModule,
  Material.MatButtonModule,
  Material.MatCheckboxModule,
  Material.MatIconModule,
  Material.MatSidenavModule,
  Material.MatListModule,
  Material.MatGridListModule,
  Material.MatFormFieldModule,
  Material.MatInputModule,
  Material.MatCardModule,
  Material.MatBadgeModule,
  Material.MatBottomSheetModule
];

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents]
})
export class MaterialDesignModule { }
