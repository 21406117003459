import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class PartnersetupService {
  baseUrl: any;
  name: any;
  id: any;
  constructor(private http: HttpClient, private router: Router, private envService: EnvService) {
    this.baseUrl = envService.apiUrl
  }
  getPartnerDetail() {
    return this.http.get(`${this.baseUrl}/partnersetup/allpartner`);
  }
  createpartner(partner) {
    return this.http.post(`${this.baseUrl}/partnersetup/create`, partner);
  }
  updatepartner(partner) {
    return this.http.post(`${this.baseUrl}/partnersetup/update`, partner);
  }
}
