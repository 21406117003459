import { Component,  OnInit, ViewChild } from '@angular/core';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { SelectionModel } from '@angular/cdk/collections';


import { BehaviorSubject} from 'rxjs';

import { FileConfigService } from '../shared/fileconfig.service'
import swal from 'sweetalert2';
import { PartnersetupService } from '../shared/partnersetup.service';


export class FoodNode {
  name: string;
  dropdown?: string;
  reference?: string;
  value?: string;
  checked?: boolean;
  dropdownSelected?: string;
  children?: FoodNode[];
}

export class ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  dropdown: string[];
  reference: String;
  value: string;
  dropdownSelected: string;
  checked: boolean;
}


let TREE_DATA: FoodNode[] = [{
  name: 'Validations',
  children: [{
    name: 'POS &  INV',
    children: [
      {
        name: 'Mandatory',
        children: [{
          name: 'Transaction Date',
          checked: false,
          dropdown: '',
          reference: 'Not Null',
          value: '',
          dropdownSelected: ''

        },
        {
          name: 'Item Number',
          checked: false,
          dropdown: '',
          reference: 'Not Null',
          value: '',
          dropdownSelected: ''
        }

        ]
      },

      {
        name: 'Validity Check',
        children: [{
          name: 'Item Number',
          checked: false,
          dropdown: '',
          reference: 'Present in Master',
          value: 'Item Number Master',
          dropdownSelected: ''
        }

        ]
      },
      {
        name: 'Duplicate Check',
        children: [{
          name: 'Item Number',
          checked: false,
          dropdown: '',
          reference: '',
          value: '',
          dropdownSelected: ''
        },
        {
          name: 'Transaction Date',
          checked: false,
          dropdown: '',
          reference: '',
          value: '',
          dropdownSelected: ''
        },
        {
          name: 'Shipped/Return Flag',
          checked: false,
          dropdown: '',
          reference: '',
          value: '',
          dropdownSelected: ''
        }
        ]
      },


    ]
  },
  {
    name: 'POS',
    children: [{
      name: 'Mandatory',
      children: [{
        name: 'Sold to Customer Name',
        checked: false,
        dropdown: '',
        reference: 'Not Null',
        value: '',
        dropdownSelected: ''

      },
      {
        name: 'Sold to Quantity',
        checked: false,
        dropdown: '',
        reference: 'Not Null',
        value: '',
        dropdownSelected: ''
      }

      ]
    },
    {
      name: 'Validity Check',
      children: [{
        name: 'Serial Number',
        checked: false,
        dropdown: '',
        reference: 'Present in Master',
        value: 'Serial Number Master',
        dropdownSelected: ''

      },
      {
        name: 'Deal Code',
        checked: false,
        dropdown: '',
        reference: 'Present in Master',
        value: 'Deal Code Master',
        dropdownSelected: ''
      }

      ]
    },
    {
      name: 'Date Check',
      children: [{
        name: 'Shipped Date',
        checked: false,
        dropdown: 'Current Date, Item Number, Site Use ID, Reported State',
        reference: 'Time between',
        value: '3 Months',
        dropdownSelected: ''

      }


      ]

    },
    {
      name: 'Value Check',
      children: [{
        name: 'Shipped Date',
        checked: false,
        dropdown: 'Current Date, Item Number, Site Use ID, Reported State',
        reference: 'Less than or equal to',
        value: '0',
        dropdownSelected: ''

      }


      ]
    },

    ]
  },
  {
    name: 'INV',
    children: [{
      name: 'Mandatory',
      children: [{
        name: 'On Hand Quantity',
        checked: false,
        dropdown: '',
        reference: 'Not Null',
        value: '',
        dropdownSelected: ''

      }


      ]
    },
    {
      name: 'Value Check',
      children: [{
        name: 'On Hand Quantity',
        checked: false,
        dropdown: '',
        reference: 'Greater than',
        value: '0',
        dropdownSelected: ''

      },
      {
        name: 'On Hand Quantity',
        checked: false,
        dropdown: '',
        reference: 'Is an Integer',
        value: '',
        dropdownSelected: ''
      }

      ]
    },

    ]
  },
  ],
},
{
  name: 'Enrichment',
  children: [{
    name: 'POS',
    children: [{
      name: 'Look-up',
      children: [{
        name: 'ST Dollar',
        checked: false,
        dropdown: 'Current Date, Item Number, Site Use ID, Reported State',
        reference: 'ASP Master',
        value: 'POS ASP',
        dropdownSelected: ''
      }]
    },
    {
      name: 'Normalize',
      children: [{
        name: 'State',
        checked: false,
        dropdown: 'Current Date, Item Number, Site Use ID, Reported State',
        reference: 'Geography Master',
        value: 'Normalized State',
        dropdownSelected: ''

      }


      ]
    },
    {
      name: 'Name Matching',
      children: [{
        name: 'Customer Name',
        checked: false,
        dropdown: '',
        reference: 'Fuzzy',
        value: 'Customer ID',
        dropdownSelected: ''

      }


      ]
    }
    ]
  },
  {
    name: 'INV',
    children: [
      {
        name: 'Look-up',
        children: [
          {
            name: 'OH Dollar',
            checked: false,
            dropdown: 'Current Date, Item Number, Site Use ID, Reported State',
            reference: 'ASP Master',
            value: 'INV ASP',
            dropdownSelected: ''
          }
        ]
      }
    ]
  }
  ],
}
];
@Component({
  selector: 'app-rule-config',
  templateUrl: './rule-config.component.html',
  styleUrls: ['./rule-config.component.css']
})
export class RuleConfigComponent implements OnInit {
  @ViewChild('treeSelector', { static: true }) tree: MatTree<any>;
  nestedDataSource:MatTreeNestedDataSource<FoodNode>

  constructor(private fileConfigService: FileConfigService, private partnerSetupService: PartnersetupService,) {
    
    this.getData();
  }
  dropdownValue: any = []
  private _transformer = (node: FoodNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    if (node.dropdown != undefined) {
      this.dropdownValue = node.dropdown.split(',')
    }
    else {
      this.dropdownValue = node.dropdown
    }
   
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new ExampleFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;

    if (this.dropdownValue != undefined && this.dropdownValue != "") {
      flatNode.dropdown = [];
      for (let i = 0; i < this.dropdownValue.length; i++) {

        flatNode.dropdown[i] = this.dropdownValue[i];
      }
    }
    else {
      flatNode.dropdown = this.dropdownValue;
    }

    flatNode.reference = node.reference;
    flatNode.value = node.value;
    flatNode.dropdownSelected = node.dropdownSelected;
    flatNode.checked = node.checked;
    flatNode.expandable = !!node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;

  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  checklistSelection = new SelectionModel<FoodNode>(true /* multiple */);
  flatNodeMap = new Map<ExampleFlatNode, FoodNode>();
  nestedNodeMap = new Map<FoodNode, ExampleFlatNode>();

  fruitGroup: any;
  selected: any;
  test: any = '';
  renderer: any;
  ruleData: any = "";
  treeResetData = [];
ValidationData:any=[];


  getLevel = (node: ExampleFlatNode) => node.level;
  isExpandable = (node: ExampleFlatNode) => node.expandable;

  getChildren = (node: FoodNode): FoodNode[] => node.children;
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  hasNoContent = (_: string, node: ExampleFlatNode) => node.name == 'Validations';

  getParentNode(node: ExampleFlatNode): ExampleFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  dataChange = new BehaviorSubject<FoodNode[]>([]);

  initialize() {
    const data = this.buildFileTree(TREE_DATA, 0);

    this.dataChange.next(data);
  }


  buildFileTree(obj: { [key: string]: any }, level: number): FoodNode[] {
    return Object.keys(obj).reduce<FoodNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new FoodNode();
      node.name = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.name = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }


  ngOnInit(): void {
    
    this.treeResetData = TREE_DATA;
     this.listData = this.treeResetData
    this.fruitGroup = new FormGroup({
  fruitList: new FormControl("", Validators.required),
    });


    // to show the matarial table tree 
    // this.dataSource.data = TREE_DATA;
    // console.log("check mat tree ", TREE_DATA);

  }


  onSubmit() {
    let userId = localStorage.getItem('userData')
    let name = this.partnerSetupService.name
    let id =  this.partnerSetupService.id
    let validation = {};
    validation = {
      "validationsfileconfig": {
        "userid": userId,
        "validations": this.listData,
        "partnername":name,
        "partnerid":id
      }
    }

    this.fileConfigService.createValidation(validation).subscribe((_data) => {
      swal.fire("Saved Validation", "Validation Has Been Saved Successfully", "success");
      this.getData();
    },
      _err => swal.fire("Validation not created", "", "error")
    )
  }

  reset() {
    let userId = localStorage.getItem('userData')
    let name = this.partnerSetupService.name
    let id =  this.partnerSetupService.id
    let validation = {};
    validation = {
      "validationsfileconfig": {
        "userid": userId,
        "validations": null,
        "partnername":name,
        "partnerid":id
      }
    }
    this.fileConfigService.createValidation(validation).subscribe((_data) => {
      swal.fire("Reseted Validation", "Validation Has Been Reseted Successfully", "success");
      this.getData();
    },
      _err => swal.fire("Validation not Reseted", "", "error")
    )
  }

  getData() {
    let userId = localStorage.getItem('userData')
    let id =  this.partnerSetupService.id
    let validation = {};
    validation = {
      "getvalidationsfileconfig": {
        "userid": userId,
        "partnerid":id
      }
    }
    this.fileConfigService.getValidation(validation).subscribe((data) => {

    this.ValidationData=data;
  
      if (this.ValidationData.length != 0) {
        if (data[0]['validations'] != null) {
          this.listData = data[0]['validations']
          this.dataSource.data = this.listData;
          this.treeControl.collapseAll();
        }
        else {
          this.dataSource.data = [];
          this.dataSource.data = TREE_DATA
          this.treeControl.collapseAll();

        }
      
      } else {
        this.dataSource.data = TREE_DATA
        this.treeControl.collapseAll();
      }
 
    },
      err => console.log("Error raised from rule-config file ", err)
    )
  }

  getNodeTree() {}

  clear() {
    this.selected = "";
  }

  selectedData: boolean = false

  activeNode(_node) {
    this.selectedData = !this.selectedData;
  }

  listData = []
  finalData: [] = []
  treeJsonData: any;
  checkedValue(event, node, _toggleControl) {
    let parentchild1 = this.getParentNode(node)
    let parentchild = this.getParentNode(parentchild1)
    let parent = this.getParentNode(parentchild)

    for (let i = 0; i < this.listData.length; i++) {
      if (this.listData[i].name == parent.name) {
        for (let j = 0; j < this.listData[i].children.length; j++) {
          if (this.listData[i].children[j].name == parentchild.name) {
            for (let k = 0; k < this.listData[i].children[j].children.length; k++) {
              if (this.listData[i].children[j].children[k].name == parentchild1.name) {
                for (let l = 0; l < this.listData[i].children[j].children[k].children.length; l++) {
                  let object1 = Object.keys(this.listData[i].children[j].children[k].children[l])
                  if (this.listData[i].children[j].children[k].children[l].name == node.name) {
                    this.listData[i].children[j].children[k].children[l][object1[1]] = event.checked
                  }
                }
              }
            }
          }
        }

      }

    }

    
  }

  onInputChange(event, node) {

    this.listData = this.treeResetData
    let parentchild1 = this.getParentNode(node)
    let parentchild = this.getParentNode(parentchild1)
    let parent = this.getParentNode(parentchild)

    for (let i = 0; i < this.listData.length; i++) {
      if (this.listData[i].name == parent.name) {
        for (let j = 0; j < this.listData[i].children.length; j++) {
          if (this.listData[i].children[j].name == parentchild.name) {
            for (let k = 0; k < this.listData[i].children[j].children.length; k++) {
              if (this.listData[i].children[j].children[k].name == parentchild1.name) {
                for (let l = 0; l < this.listData[i].children[j].children[k].children.length; l++) {
                  let object1 = Object.keys(this.listData[i].children[j].children[k].children[l])
                  if (this.listData[i].children[j].children[k].children[l].name == node.name) {
                    this.listData[i].children[j].children[k].children[l][object1[4]] = event.target.value
                  }
                }
              }
            }
          }
        }

      }

    }
   

  }
  onSelect(event, node) {
   
    let parentchild1 = this.getParentNode(node)
    let parentchild = this.getParentNode(parentchild1)
    let parent = this.getParentNode(parentchild)

    for (let i = 0; i < this.listData.length; i++) {
      if (this.listData[i].name == parent.name) {
        for (let j = 0; j < this.listData[i].children.length; j++) {
          if (this.listData[i].children[j].name == parentchild.name) {
            for (let k = 0; k < this.listData[i].children[j].children.length; k++) {
              if (this.listData[i].children[j].children[k].name == parentchild1.name) {
                for (let l = 0; l < this.listData[i].children[j].children[k].children.length; l++) {
                  let object1 = Object.keys(this.listData[i].children[j].children[k].children[l])
                  if (this.listData[i].children[j].children[k].children[l].name == node.name) {
                    if (event.value == "None") {
                      this.listData[i].children[j].children[k].children[l][object1[5]] = ''
                    }
                    else {
                      this.listData[i].children[j].children[k].children[l][object1[5]] = event.value
                    }

                  }
                }
              }
            }
          }
        }

      }

    }
   

  }
 

}



