import { Injectable } from '@angular/core';

export enum Environment {
  Prod = 'prod',
  Dev = 'dev',
  Local = 'local',
}

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  private _env: Environment;
  private _apiUrl: string;
  private _googleCapchaSecretKey: string;

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get googleCapchaSecretKey(): string {
    return this._googleCapchaSecretKey;
  }


  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;
    console.log("hostname", hostname);
    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Local;
      // this._apiUrl = 'https://dev.fareedsheiknco.com/fareed_co';
      this._apiUrl = 'http://localhost:5000/fareed_co';
      this._googleCapchaSecretKey = '6LcwWYkfAAAAAFq5rwrw6VLYh_bmSyNWtb1n23mX';
    } else if (/^dev-client.fareedsheiknco.com/.test(hostname)) {
      this._env = Environment.Dev;
      this._apiUrl = 'https://dev-client.fareedsheiknco.com/fareed_co';
      this._googleCapchaSecretKey = '6LfOl50fAAAAAP278NEhytmVtzEO1Hd7WSykWmKH';
    } else if (/^customer.fareedsheiknco.com/.test(hostname)) {
      this._env = Environment.Prod;
      this._apiUrl = 'https://customer.fareedsheiknco.com/fareed_co';
      this._googleCapchaSecretKey = '6LfOl50fAAAAAP278NEhytmVtzEO1Hd7WSykWmKH';
    } else if (/^client.fareedsheiknco.com/.test(hostname)) {
      this._env = Environment.Prod;
      this._apiUrl = 'https://client.fareedsheiknco.com/fareed_co';
      this._googleCapchaSecretKey = '6LcBjYkmAAAAAC2bireAlim9hlOu5b2thiK-tOKH';
    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
