import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RuleConfigRoutingModule } from './rule-config-routing.module';
import { RuleConfigComponent } from './rule-config.component';
import { MatButtonModule, MatIconModule, MatTreeModule, MatCheckboxModule,  MatFormFieldModule ,MatInputModule, MatSelectModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [RuleConfigComponent],
  imports: [
    CommonModule,
    RuleConfigRoutingModule,
    MatTreeModule,
    MatIconModule, 
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule ,
    MatCheckboxModule ,
    MatFormFieldModule  ,
    MatInputModule,
    MatSelectModule  
  ],
  exports:[RuleConfigComponent]
})
export class RuleConfigModule { }
