import {Injectable} from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'overview',
    title: 'Overview',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboards',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      }   
    ]
  },
  {
    id: 'processing',
    title: 'Processing',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'results',
        title: 'Data Files',
        type: 'item',
        url: '/results',
        target: false,
        icon: 'feather icon-layout'
      },
    ]
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'user',
        title: 'Corporate Users',
        type: 'item',
        url: '/user',
        target: false,
        icon: 'feather icon-layout'
      },
      {
        id: 'personalUser',
        title: 'Personal Users',
        type: 'item',
        url: '/personalUser',
        target: false,
        icon: 'feather icon-layout'
      },
      {
        id: 'audit',
        title: 'Audit',
        type: 'item',
        url: '/user/audit',
        target: false,
        icon: 'feather icon-layout'
      },
   
     ]
   }
 
];

@Injectable()
export class NavigationItem {
  constructor(private authservice: AuthService) { }
  public get() {
    let NavigationData =[];
    let role = localStorage.getItem('role')
    if( role == "Admin" || role == "CSR"){
      return NavigationItems;
    }
    else{
      return  NavigationData = [
        {
          id: 'overview',
          title: 'Overview',
          type: 'group',
          icon: 'feather icon-monitor',
          children: [
            {
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              url: '/dashboard/analytics',
              icon: 'feather icon-home'
            }   
          ]
        },
      
        {
          id: 'processing',
          title: 'Processing',
          type: 'group',
          icon: 'feather icon-monitor',
          children: [
           
            {
              id: 'results',
              title: 'Data Files',
              type: 'item',
              url: '/results',
              target: false,
              icon: 'feather icon-layout'
            },
          
          ]
        },
      
      ];
    }
   
  }
}
