import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';

import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor( private router: Router) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token =localStorage.getItem('accessToken');
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        // 'x-tenant-id': environment.app_id // Sending the tenant id on every request. 
      }
    });
    return next.handle(request).pipe(
  
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.clearCookie()
          }
        }
        return throwError(err);
      }));
  }
  clearCookie() {
    localStorage.clear();
    this.router.navigate(['/'])
  }
}