import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./theme/shared/shared.module";
import { AppComponent } from "./app.component";
import { AdminComponent } from "./theme/layout/admin/admin.component";
import { AuthComponent } from "./theme/layout/auth/auth.component";
import { NavigationComponent } from "./theme/layout/admin/navigation/navigation.component";
import { NavContentComponent } from "./theme/layout/admin/navigation/nav-content/nav-content.component";
import { NavGroupComponent } from "./theme/layout/admin/navigation/nav-content/nav-group/nav-group.component";
import { NavCollapseComponent } from "./theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component";
import { NavItemComponent } from "./theme/layout/admin/navigation/nav-content/nav-item/nav-item.component";
import { NavBarComponent } from "./theme/layout/admin/nav-bar/nav-bar.component";
import { NavLeftComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-left.component";
import { NavSearchComponent } from "./theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component";
import { NavRightComponent } from "./theme/layout/admin/nav-bar/nav-right/nav-right.component";
import { ConfigurationComponent } from "./theme/layout/admin/configuration/configuration.component";
import { ToggleFullScreenDirective } from "./theme/shared/full-screen/toggle-full-screen";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
/* Menu Items */
import { NavigationItem } from "./theme/layout/admin/navigation/navigation";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { CdkTreeModule } from "@angular/cdk/tree";
import { FileConfigModule } from "./file-config/file-config.module";
import { Test1Component } from "./file-config/test1/test1.component";
import { LoaderComponent } from "./loader/loader.component";
import { LoaderService } from "./shared/loader.service";
import { LoaderInterceptor } from "./loader/loader.interceptor";
import { MaterialDesignModule } from "./shared/material-design.module";

import { TokenInterceptor } from "./shared/token.interceptor";
import { MatFormFieldModule, MatSelectModule } from "@angular/material";
import { NotfoundComponent } from './notfound/notfound.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EnvService } from "./shared/env.service";

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    LoaderComponent,
    NotfoundComponent,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    ReactiveFormsModule,
    CdkTreeModule,
    FileConfigModule,
    MaterialDesignModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonToggleModule,
  ],

  entryComponents: [Test1Component],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => envService.init(),
      deps: [EnvService],
      multi: true
    },
    NavigationItem,
    LoaderService,

    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {

      provide: HTTP_INTERCEPTORS,

      useClass: TokenInterceptor,

      multi: true,

    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
